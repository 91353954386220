import DataObjectParser from 'dataobject-parser';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, value)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export async function dryRun(form, url) {
    form.clearErrors();
    return await window.axios
        .post(url, form.data(), { headers: { 'X-Dry-Run': true } })
        .then((res) => {
            return true;
        })
        .catch(function (error) {
            if (error?.response?.status === 422) {
                form.setError(error.response.data.errors);
            }
            return false;
        });
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function capitalFirstLetter(string) {
    if (typeof string == 'undefined') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ucFirst(string) {
    return capitalFirstLetter(string);
}
